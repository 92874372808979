import React, { Component } from 'react';
import { Router, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';
import moment from 'moment';
import 'moment/locale/fr';
import './App.scss';

//Local Libs
import { history } from './_helpers';
import { Loader } from './_components/Loader';

// Containers
import { FullPageLayout } from './_containers';


// set language
moment.locale('fr');

// Pages
const loading = () => <Loader />;


const ItemList = Loadable({
  loader: () => import('./_pages/ItemList'),
  loading
});


const Page404 = Loadable({
  loader: () => import('./_pages/Page404'),
  loading
});

const ItemDetail = Loadable({
  loader: () => import('./_pages/ItemDetail'),
  loading
});


const ResetPwd = Loadable({
  loader: () => import('./_pages/ResetPwd'),
  loading
});


const TermsConditions = Loadable({
  loader: () => import('./_pages/TermsConditions'),
  loading
});

class App extends Component {
  render() {
    return (
      <Router history={history}>
          <Switch>
            <FullPageLayout exact path={`/`} name="Home Page" component={ItemList} />
            <FullPageLayout exact path={`/resetpassword`} name="Reset password" component={ResetPwd} showDefaultHeader={false} />
            <FullPageLayout exact path={`/terms-and-conditions`} name="Terms" component={TermsConditions} showDefaultHeader={false} />
            <FullPageLayout exact path={`/404`} name="Page404" component={Page404} showDefaultHeader={false}/>
            <FullPageLayout exact path={`/items`} name="Item list Page" component={ItemList} showDefaultHeader={true} />
            <FullPageLayout exact path={`/items/category/:category_slug`} name="Home Page" component={ItemList} />
            <FullPageLayout exact path={`/category/:category_slug/item/:item_slug`} name="Item Detail Page" component={ItemDetail} />
            <FullPageLayout exact path="*" name="Page404" component={Page404}/>
          </Switch>
        </Router>
    );
  }
}
export default App;
